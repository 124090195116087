import React from "react"
import Layout from "components/layout"
import CreditCardsLandingPage from "components/content/credit-cards-external-landing-page"

const LandingPage = (props: any) => {
  return null
  // return (
  //   <Layout 
  //     title="Credit Card Comparison | Stay or Go"
  //     description="Compare credit card offers from all the major banks and popular brands."
  //     image="/video/StayOrGo_Animation_01.jpf"
  //     urlSlug="credit-cards-landing"
  //   >
  //     <CreditCardsLandingPage />
  //   </Layout>
  // )
}

export default LandingPage